@import url('https://fonts.googleapis.com/css?family=Quicksand');

* {
    font-family: Quicksand, sans-serif;
}
// body {
//     position:absolute; top:0; bottom:0; right:0; left:0;
//     margin: 0;
//     padding: 0;

// } 

html, body, div#root  {

    // height: 100%;
    // width: 100%;
    margin: 0;
    padding: 0;
}

/* Scrollbar */
::-webkit-scrollbar {
    background: rgba(0, 0, 0, 0.2) !important;
    width: 7px; /* The lucky number, perfect balance x) */
}

::-webkit-scrollbar-thumb {
    background: #333 !important;
}
