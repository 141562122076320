$default: #3eccbb;
$default-dark: #279185;
$vip: #FFD15C;
$vip-dark: #e0a100;
$premium: #FD6D49;
$premium-dark: #CA2A02;

.itemWrapper {
  display: flex;
  flex-direction: column;

  max-width: 420px;
  & > h5 {
    text-transform: capitalize;
  }
  div.img-container {
    &.image-default {
      background-image:
        linear-gradient(90deg,
        $default 0%,
        $default-dark 50%,
        $default 100%
      );
    }

    &.image-vip {
      background-image:
        linear-gradient(90deg,
        $vip 0%,
        $vip-dark 50%,
        $vip 100%
      );
    }

    &.image-premium {
      background-image:
        linear-gradient(90deg,
        $premium 0%,
        $premium-dark 50%,
        $premium 100%
      );
    }

    & > img.itemImg {
      max-width: 100%;
      padding: 6px;
      margin-bottom: -5px;
    }

    @keyframes vip-border {
      from {
        background-position: -3000px;
      }
      to {
        background-position: 0px;
      }
    }

    max-width: 100%;

    position: relative;
    z-index: 0;
    overflow: hidden;

    animation: rotate 12s infinite linear;
    -webkit-animation: rotate 12s infinite linear;
  }

  .chipWrapper {
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    .chip {
      margin: 2px;    
    }
    .chipdefault {
      border-color: $default;
    }
    .chipvip {
      border-color: $vip;
    }
    .chippremium {
      border-color: $premium;
    }
  }

  .default, .vip, .premium {
    text-transform: capitalize;
  }
  .default {
    background-color: $default;
  }
  .vip {
    background-color: $vip;
  }
  .premium {
    background-color: $premium;
  }
  .close-icon {
    font-size: 16px;
    color: tomato;
  }
  .check-icon {
    font-size: 16px;
    color: limegreen;
  }
}
