@import url(https://fonts.googleapis.com/css?family=Quicksand);
body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

*{font-family:Quicksand, sans-serif}html,body,div#root{margin:0;padding:0}::-webkit-scrollbar{background:rgba(0,0,0,0.2) !important;width:7px}::-webkit-scrollbar-thumb{background:#333 !important}

.footer-container{width:100%;height:96px;margin-top:auto;display:flex;justify-content:center;align-items:center;color:#fff}.footer-container svg{color:#fff}.footer-container .MuiIconButton-root{margin:0 4px}.footer-container .rights{text-align:center}.footer-container .signature{text-align:center;color:#888;font-size:12px}.footer-container .signature a{text-decoration:none;color:inherit;font-weight:bold;margin:0 4px}.footer-container .signature a:hover{color:#666;transition:color .2s}.footer-container .signature a:not(:hover){transition:color .2s}

.page-body{padding-bottom:20px;margin-top:48px;min-height:calc(100vh - 144px)}.fade-appear,.fade-enter{opacity:0.02}.fade-appear.fade-appear-active,.fade-enter.fade-enter-active{opacity:1;transition:opacity .3s ease-in}.fade-leave{opacity:1}.fade-leave.fade-leave-active{opacity:0.02;transition:opacity .3s ease-in}

.card{width:100%;position:relative}.card .seperator{width:3000px;height:7px;animation:rotate 3s infinite linear;-webkit-animation:rotate 3s infinite linear}.card .card-content{min-height:143px;display:flex;flex-direction:column}.card .card-content .hostname{letter-spacing:0.1px;line-height:20px}.card .card-content .location{width:16px;height:auto}.card .card-content .mod-name{text-transform:uppercase;position:absolute;right:10px;top:172px;color:#fff}.card .ip-container{padding-top:4px;padding-bottom:0}.card .show-players{position:absolute;right:4px;bottom:4px}@-webkit-keyframes rotate{from{background-position:-3000px}to{background-position:0px}}@keyframes rotate{from{background-position:-3000px}to{background-position:0px}}.gradient-green{background:#05ff00;background:linear-gradient(90deg, #05ff00 0%, #50ff47 25%, #2ab525 50%, #98ff9b 77%, #05ff00 100%)}.gradient-red{background:red;background:linear-gradient(90deg, red 0%, #e80000 25%, #bf2020 50%, #ff6e6e 77%, red 100%)}

.main{height:100%}

.page-404{width:100%;height:calc(100vh - 174px);display:flex;flex-direction:column;align-items:center;justify-content:center}

div.carousel .slick-list{height:calc(100vh - 48px);width:100%}div.carousel .slick-list .slick-track{width:100%;margin:auto}div.carousel .slick-list .slick-track .slick-slide div.background-item{position:relative}div.carousel .slick-list .slick-track .slick-slide div.background-item>div{background-size:cover;background-position:center;width:100%;height:100vh;display:flex;justify-content:center}div.carousel .slick-list .slick-track .slick-slide div.background-item>div>div.caption{color:#fff;text-align:center;bottom:90px;min-height:80px;left:0;position:absolute;width:100%}div.carousel .slick-dots{bottom:25px}div.carousel .slick-dots li.slick-active button::before{color:#fff;opacity:1}div.carousel .slick-arrow{z-index:1;width:20vw;height:100%}div.carousel .slick-arrow:before{content:''}div.carousel .slick-arrow.slick-prev{left:0}div.carousel .slick-arrow.slick-next{right:0}div.carousel .slick-dots li button::before{color:#fff;opacity:0.7}

.connect-snackbar{position:fixed;left:32px;bottom:32px;background-color:#333;color:#fff;padding:4px 16px;z-index:2;box-shadow:0px 4px 1px -1px rgba(0,0,0,0.2),0px 4px 7px 0px rgba(0,0,0,0.14),0px 4px 3px 0px rgba(0,0,0,0.12)}.connect-snackbar>button{margin-left:8px}.connect-snackbar>button .play-icon{color:green;padding:0}

/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v53/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}
  
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
div.MuiTabs-root{justify-content:center}div.MuiTabs-root .MuiTabs-scroller.MuiTabs-scrollable{flex-grow:0}

.itemWrapper{display:flex;flex-direction:column;max-width:420px}.itemWrapper>h5{text-transform:capitalize}.itemWrapper div.img-container{max-width:100%;position:relative;z-index:0;overflow:hidden;animation:rotate 12s infinite linear;-webkit-animation:rotate 12s infinite linear}.itemWrapper div.img-container.image-default{background-image:linear-gradient(90deg, #3eccbb 0%, #279185 50%, #3eccbb 100%)}.itemWrapper div.img-container.image-vip{background-image:linear-gradient(90deg, #FFD15C 0%, #e0a100 50%, #FFD15C 100%)}.itemWrapper div.img-container.image-premium{background-image:linear-gradient(90deg, #FD6D49 0%, #CA2A02 50%, #FD6D49 100%)}.itemWrapper div.img-container>img.itemImg{max-width:100%;padding:6px;margin-bottom:-5px}@-webkit-keyframes vip-border{from{background-position:-3000px}to{background-position:0px}}@keyframes vip-border{from{background-position:-3000px}to{background-position:0px}}.itemWrapper .chipWrapper{width:200px;display:flex;flex-direction:row;justify-content:flex-end;flex-wrap:wrap}.itemWrapper .chipWrapper .chip{margin:2px}.itemWrapper .chipWrapper .chipdefault{border-color:#3eccbb}.itemWrapper .chipWrapper .chipvip{border-color:#FFD15C}.itemWrapper .chipWrapper .chippremium{border-color:#FD6D49}.itemWrapper .default,.itemWrapper .vip,.itemWrapper .premium{text-transform:capitalize}.itemWrapper .default{background-color:#3eccbb}.itemWrapper .vip{background-color:#FFD15C}.itemWrapper .premium{background-color:#FD6D49}.itemWrapper .close-icon{font-size:16px;color:tomato}.itemWrapper .check-icon{font-size:16px;color:limegreen}

.image-container{background-image:url(/static/media/background.b02a5fd2.jpg);background-size:cover;background-position:center center;display:flex;width:100%;height:calc(100vh - 48px);flex-direction:column;justify-content:center;align-items:center}.image-container .header{position:absolute;text-align:center;color:#fff}.chip-container{max-width:600px;padding-bottom:10px}.chip-container>*{margin:5px}

