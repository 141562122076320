.image-container{
    background-image: url(../../assets/img/1v1/background.jpg);
    background-size: cover;
    background-position: center center;
    display: flex;
    width: 100%;
    height: calc(100vh - 48px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .header{
        position: absolute;
        text-align: center;
        color: #fff;
    }
}

.chip-container{
   max-width: 600px;
   padding-bottom: 10px;
    &>*{
        margin: 5px;
    }
}